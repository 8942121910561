@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url("https://fonts.googleapis.com/css?family=Raleway:400,300,500,700");

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');


@font-face {
  font-family: "Avenir-Black";
  src: local("Avenir-Black"), url("./fonts/Avenir-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-BlackOblique";
  src: local("Avenir-BlackOblique"), url("./fonts/Avenir-BlackOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Book";
  src: local("Avenir-Book"), url("./fonts/Avenir-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-BookOblique";
  src: local("Avenir-BookOblique"), url("./fonts/Avenir-BookOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Heavy";
  src: local("Avenir-Heavy"), url("./fonts/Avenir-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-HeavyOblique";
  src: local("Avenir-HeavyOblique"), url("./fonts/Avenir-HeavyOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Light";
  src: local("Avenir-Light"), url("./fonts/Avenir-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-LightOblique";
  src: local("Avenir-LightOblique"), url("./fonts/Avenir-LightOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Medium";
  src: local("Avenir-Medium"), url("./fonts/Avenir-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-MediumOblique";
  src: local("Avenir-MediumOblique"), url("./fonts/Avenir-MediumOblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Oblique";
  src: local("Avenir-Oblique"), url("./fonts/Avenir-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir-Roman"), url("./fonts/Avenir-Roman.ttf") format("truetype");
}

@font-face {
  font-family: "BonVivant";
  src: local("BonVivant"), url("./fonts/BonVivantSerifBold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "BonVivantLPC";
  src: local("BonVivantLPC"), url("./fonts/BonVivantSerifBold_LPC.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BonVivantLPC2";
  src: local("BonVivantLPC2"), url("./fonts/BonVivantSerifBold_LPC2.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "CommuterSansSemiBold";
  src: local("CommuterSansSemiBold"), url("./fonts/CommuterSansSemiBold.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "EditorsNoteRegular";
  src: local("EditorsNoteRegular"), url("./fonts/EditorsNoteRegular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "EditorsNoteExtralightItalic";
  src: local("EditorsNoteExtralightItalic"), url("./fonts/EditorsNoteExtralightItalic.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "EditorsNoteItalic";
  src: local("EditorsNoteItalic"), url("./fonts/EditorsNoteItalic.otf") format("opentype");
  font-weight: normal;
}


body {
  margin: 0;
  font-family: 'Avenir-Book',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.zoom-hand:hover {
  cursor: zoom-in;
}



.headTitle {
  font-size: 12px;
  font-family: 'Avenir-Heavy';
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight:500;
}

.headValue {
  font-size: 14px;
  font-family: 'Avenir-Book';
  text-transform: capitalize;
  letter-spacing: 1px;
}